import Hero from '@src/modules/home/Hero';
import Image from 'next/image';
import Features2 from '@src/modules/home/Features2';
import React, { useEffect } from 'react';
import Testimonial from '@src/modules/home/Testimonial';
import { useThemeContext } from '@crema/context/ThemeContextProvider';
import Box from '@mui/material/Box';
import { useRouter } from 'next/router';
import TestimonialRow from '@src/modules/home/TestimonialRow';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import LooksOutlinedIcon from '@mui/icons-material/LooksOutlined';
import Features3 from '@src/modules/home/Features3';

type Props = {
  productRef: React.MutableRefObject<null>;
  aboutRef: React.MutableRefObject<null>;
};

const HomeIndex = ({ productRef, aboutRef }: Props) => {
  const { theme } = useThemeContext();
  const { asPath } = useRouter();

  useEffect(() => {
    const hash = asPath.split('#')[1];

    if (hash && hash === 'product' && productRef.current) {
      (productRef.current as any).scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }

    if (hash && hash === 'about-us' && aboutRef.current) {
      (aboutRef.current as any).scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [asPath]);

  return (
    <>
      <div
        className="hero-banner-three position-relative pt-200 md-pt-150"
        data-aos="fade-right"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Hero />
            </div>
          </div>
        </div>

        <Box className={'skip-bottom'} sx={{ textAlign: 'center' }}>
          <Image
            ref={productRef}
            src={'/images/mac_app_2.png'}
            alt=""
            width={1992 * 0.65}
            height={1164 * 0.65}
            style={{
              // maxWidth: '80%',
              display: 'block',
              margin: '0 auto',
              position: 'relative',
              top: '-100px',
              width: 'auto',
              height: 'auto',
              maxHeight: '80vh',
              maxWidth: '80vw',
            }}
          />
        </Box>
        {/* /.container */}
      </div>
      {/*
              =============================================
              Feature Section Nine
              ==============================================
              */}
      <div className="fancy-feature-nine position-relative pt-50 xl-pt-50">
        <div className="container">
          <div className="row align-items-center">
            <div className={'col-12'}>
              <p className="text-xlg mb-50 lg-mb-30 pe-xxl-4 text-center fw-semibold">
                Save time throughout interviews. <br /> Bring your candidate
                experience to the next level.
              </p>
            </div>

            <div className="col-lg-5 mb-50 mb-lg-0">
              <div className="block-style-three" data-data-aos="fade-right">
                <div className="title-style-four">
                  <div className="sc-title" style={{ color: '#6A45FF' }}>
                    Save 45% time
                  </div>
                  <h2 className="main-title fw-500 tx-dark m0">
                    Hire faster by <span className="red">using our app.</span>
                  </h2>
                </div>
                <div className="pe-xxl-5 px-3 pe-md-5">
                  <div className="fs-18 pt-45 pe-md-5 pe-xxl-5 ml-n10">
                    <div className={'row'}>
                      <div className="col-4 text-center">
                        <Box
                          className={'text-lg'}
                          sx={{
                            backgroundColor: theme.palette.logo.violet,
                            borderRadius: '50%',
                            width: '65px',
                            height: '65px',
                            display: 'block',
                            textAlign: 'center',
                            lineHeight: '65px',
                            color: 'white',
                            fontWeight: 'bold',
                            margin: '0 auto',
                            marginBottom: 2,
                          }}
                        >
                          1
                        </Box>
                        Create
                        <br /> job role
                      </div>
                      <div className="col-4 text-center">
                        <Box
                          className={'text-lg'}
                          sx={{
                            backgroundColor: theme.palette.logo.violet,
                            borderRadius: '50%',
                            width: '65px',
                            height: '65px',
                            display: 'block',
                            textAlign: 'center',
                            lineHeight: '65px',
                            color: 'white',
                            fontWeight: 'bold',
                            margin: '0 auto',
                            marginBottom: 2,
                          }}
                        >
                          2
                        </Box>
                        Select
                        <br /> soft skills
                      </div>
                      <div className="col-4 text-center">
                        <Box
                          className={'text-lg'}
                          sx={{
                            backgroundColor: theme.palette.logo.violet,
                            borderRadius: '50%',
                            width: '65px',
                            height: '65px',
                            display: 'block',
                            textAlign: 'center',
                            lineHeight: '65px',
                            color: 'white',
                            fontWeight: 'bold',
                            margin: '0 auto',
                            marginBottom: 2,
                          }}
                        >
                          3
                        </Box>
                        Generate
                        <br /> interview guide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.block-style-three */}
            </div>
            <div
              className="col-lg-6 offset-lg-1 col-md-9 m-autox text-lg-right"
              data-data-aos="fade-left"
            >
              <Features3 />
              {/*<WhyChoose />*/}
              {/* /.screen-container */}
            </div>
          </div>
        </div>
        {/* /.container */}
      </div>
      {/*
              =============================================
              Feature Section Nine
              ==============================================
              */}
      <div className="fancy-feature-nine position-relative pt-150 lg-pt-50">
        <div className="container">
          <div className="row align-items-center">
            {/*<div className={'col-12'}>
                      <p className="text-lg mb-50 lg-mb-30 pe-xxl-4  text-center">
                        Save time throughout interviews. <br /> Bring your candidate
                        experience to the next level.
                      </p>
                    </div>
      */}
            <div className="col-lg-4 order-0 mb-3 mb-lg-0">
              <div className="block-style-three" data-data-aos="fade-right">
                <div className="title-style-four">
                  <div className="sc-title" style={{ color: '#6A45FF' }}>
                    interviewsUp
                  </div>
                  <h2 className="main-title fw-500 tx-dark m0 mb-2">
                    Unmatched <span className="yellow">experience.</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12 order-1 mb-3 mb-lg-0">
              <Image
                src={'/images/pic1.png'}
                alt=""
                width={2531 * 0.3}
                height={1138 * 0.3}
                style={{
                  // maxWidth: '100%',
                  // maxWidth: '400px',
                  maxHeight: '240px',
                  width: 'auto',
                  display: 'block',
                  margin: '0 auto',
                  position: 'relative',
                  // top: '-100px',
                }}
              />
            </div>
            <div
              className="col-lg-3 col-sm-6 order-lg-2 order-3"
              style={{ paddingTop: 5 }}
            >
              <Image
                src={'/images/pic3.png'}
                alt=""
                width={1350 * 0.2}
                height={1305 * 0.2}
                style={{
                  // maxWidth: '100%',
                  maxWidth: '200px',
                  display: 'block',
                  margin: '0 auto',
                  position: 'relative',
                }}
              />
            </div>

            <div className="col-lg-6 col-sm-6 col-12 col-md-6 order-lg-3 order-2">
              <p className="text-xlg mb-50 lg-mb-30 pe-xxl-4 text-lg-start text-center fw-bold">
                InterviewsUp delivers optimal
                <br className="d-none d-lg-block" /> questions customised for
                specific
                <br className="d-none d-lg-block" /> soft skills and experience
                levels.
              </p>
            </div>
            <div className="offset-lg-1 col-lg-5 order-4">
              <Image
                src={'/images/pic2.png'}
                alt=""
                width={2610 * 0.5}
                height={1136 * 0.5}
                style={{
                  // maxWidth: '100%',
                  // maxWidth: '400px',
                  maxHeight: 240,
                  width: 'auto',
                  display: 'block',
                  margin: '0 auto',
                  position: 'relative',
                  // top: '-100px',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/*
              =============================================
              Feature Section Seven
              ==============================================
              */}
      <div className="fancy-feature-seven pt-200 lg-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-xl-6 col-lg-5 bg-round-bubble order-last order-lg-0"
              data-aos="fade-right"
            >
              <div
                className="container"
                style={{
                  width: '80%',
                  // backgroundImage: 'url(/images/pic4.png)',
                  // backgroundSize: 'contain',
                }}
              >
                <div className="row">
                  <div className="col-6">
                    <div
                      style={{
                        backgroundColor: '#FFF',
                        borderRadius: '15px',
                        padding: '20px',
                      }}
                    >
                      <Image
                        src={'/images/ico1.jpg'}
                        width="225"
                        height="225"
                        style={{
                          width: '30%',
                          height: 'auto',
                          marginBottom: 15,
                        }}
                        alt=""
                      />

                      <p className="fs-20">
                        2121 interviewing questions adapted to different
                        seniority levels.
                      </p>
                    </div>

                    <div
                      style={{
                        marginTop: '25px',
                        backgroundColor: '#FFF',
                        borderRadius: '15px',
                        padding: '20px',
                      }}
                    >
                      <Image
                        src={'/images/ico2.jpg'}
                        width="225"
                        height="225"
                        style={{
                          width: '30%',
                          height: 'auto',
                          marginBottom: 15,
                        }}
                        alt=""
                      />

                      <p className="fs-20">
                        70 soft skills competencies to choose from.
                      </p>
                    </div>
                  </div>
                  <div className="col-6" style={{ paddingTop: '20%' }}>
                    <div
                      style={{
                        backgroundColor: '#FFF',
                        borderRadius: '15px',
                        padding: '20px',
                        // marginBottom: 15,
                      }}
                    >
                      <Image
                        src={'/images/ico3.jpg'}
                        width="225"
                        height="225"
                        style={{
                          width: '30%',
                          height: 'auto',
                          marginBottom: 15,
                        }}
                        alt=""
                      />

                      <p className="fs-20">
                        4800 job role titles to help you define what you are
                        looking for.
                      </p>
                    </div>

                    <div
                      style={{
                        marginTop: '25px',
                        backgroundColor: '#FFF',
                        borderRadius: '15px',
                        padding: '20px',
                        // marginBottom: 15,
                      }}
                    >
                      <Image
                        src={'/images/ico4.jpg'}
                        width="225"
                        height="225"
                        style={{
                          width: '30%',
                          height: 'auto',
                          marginBottom: 15,
                        }}
                        alt=""
                      />

                      <p className="fs-20">
                        Learning Hub with resources to help you ace interviews.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 order-first order-lg-0"
              data-aos="fade-right"
              style={{ paddingLeft: '10%' }}
            >
              <div className="title-style-four text-center text-lg-start">
                <div className="sc-title" style={{ color: '#6A45FF' }}>
                  Features
                </div>

                <h2 className="main-title fw-500 tx-dark mb-30">
                  You choose <span className="green">interviewsUp.</span>
                </h2>

                <p className="text-xlg mb-50 lg-mb-30 pe-xxl-4 text-lg-start fw-bold">
                  We have your back. AI helps you to select the best
                  competencies for your role.
                </p>

                <p className="text-xlg mb-50 lg-mb-30 pe-xxl-4  text-lg-start fw-bold">
                  InterviewsUp gives you the best questions.
                </p>
              </div>
            </div>
            {/* End .col-6 */}
          </div>
        </div>
        {/* End .container */}

        {/* /.container */}
      </div>

      <div className="fancy-feature-seven pt-200 lg-pt-100">
        <div className="containerx">
          <div className="row align-items-center">
            <div className={'col-12'}>
              <div
                className="title-style-four text-center lg-pb-30"
                ref={aboutRef}
              >
                <h3 className="main-title h-hero-heading fw-500 tx-dark mb-100">
                  Our mission is to equip you with the <br />
                  <span className="yellow">best interview questions</span> to
                  help you <br />
                  select the right candidates.
                </h3>
              </div>
            </div>

            <div
              className="col-xl-5 offset-xl-1 offset-lg-0 col-lg-6"
              data-aos="fade-right"
            >
              <div
                className="container"
                style={
                  {
                    // backgroundImage: 'url(/images/pic4.png)',
                    // backgroundSize: 'contain',
                  }
                }
              >
                <div className="row bg-round-bubble about">
                  <div
                    className={'m-lg-5'}
                    style={{
                      // backgroundColor: '#FFF',
                      // borderRadius: '15px',
                      padding: '50px',
                    }}
                  >
                    <p
                      className={'fs-24 fw-semibold'}
                      style={{ marginBottom: 20 }}
                    >
                      InterviewsUp helps HR professionals,{' '}
                      <br className={'d-none'} />
                      hiring managers, and individual contributors{' '}
                      <br className={'d-none'} /> in moving beyond standard
                      questions.
                    </p>

                    <p
                      className={'fs-24 fw-semibold'}
                      style={{ marginTop: 60 }}
                    >
                      It enables them to creatively design their{' '}
                      <br className={'d-none'} /> approach to more effectively
                      assess soft skills <br className={'d-none'} />
                      across various roles and seniority levels.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6"
              data-aos="fade-right"
              style={{ paddingLeft: '10%' }}
            >
              <Image
                src={'/images/about-us-app-page.png'}
                alt=""
                width={1992 * 0.65}
                height={1164 * 0.65}
                style={{
                  maxWidth: '100%',
                  display: 'block',
                  margin: '0 auto',
                  // position: 'relative',
                }}
              />
            </div>
            {/* End .col-6 */}
          </div>
        </div>
        {/* End .container */}

        {/* /.container */}
      </div>

      <div className="fancy-feature-nine position-relative pt-100 lg-pt-50">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div
                className="block-style-three text-center m-auto"
                style={{ width: '70%' }}
                data-data-aos="fade-right"
              >
                <div className={'row'}>
                  <div className={'col-6'}>
                    <Avatar
                      className="m-auto"
                      sx={{
                        bgcolor: alpha(theme.palette.logo.blue, 0.65),
                        padding: 10,
                      }}
                    >
                      <LocalLibraryOutlinedIcon style={{ fontSize: 40 }} />
                    </Avatar>
                    <h6 className="mt-4">Learning</h6>
                  </div>
                  <div className={'col-6'}>
                    <Avatar
                      className="m-auto"
                      sx={{
                        bgcolor: alpha(theme.palette.logo.violet, 0.65),
                        padding: 10,
                      }}
                    >
                      <AutoAwesomeOutlinedIcon style={{ fontSize: 40 }} />
                    </Avatar>
                    <h6 className="mt-4">Creativity</h6>
                  </div>
                </div>
                <Box className={'row'} sx={{ mt: 10 }}>
                  <div className={'col-6'}>
                    <Avatar
                      className="m-auto"
                      sx={{
                        bgcolor: alpha(theme.palette.logo.red, 0.65),
                        padding: 10,
                      }}
                    >
                      <ViewQuiltOutlinedIcon style={{ fontSize: 40 }} />
                    </Avatar>
                    <h6 className="mt-4">Efficiency</h6>
                  </div>
                  <div className={'col-6'}>
                    <Avatar
                      className="m-auto"
                      sx={{
                        bgcolor: alpha(theme.palette.logo.yellow, 0.65),
                        padding: 10,
                      }}
                    >
                      <LooksOutlinedIcon style={{ fontSize: 40 }} />
                    </Avatar>
                    <h6 className="mt-4">Positive experiences</h6>
                  </div>
                </Box>
              </div>
              {/* /.block-style-three */}
            </div>
            <div
              className="col-lg-5 offset-lg-1 col-md-9 m-autox text-lg-right"
              data-data-aos="fade-left"
            >
              <div className="title-style-four text-center text-lg-start lg-pb-30">
                <div className="sc-title" style={{ color: '#6A45FF' }}>
                  Values
                </div>
                <h2 className="main-title fw-500 tx-dark  mb-30">
                  <span className="blue">We value...</span>
                </h2>
              </div>

              <Features2 />
            </div>
          </div>
        </div>
      </div>

      {/*
              =============================================
              Feature Section Seven
              ==============================================
              */}

      <TestimonialRow />

      {/*
                =====================================================
                Fancy Short Banner Four
                =====================================================
                */}
      {/*<div className="fancy-short-banner-four position-relative">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-10 m-auto">
                        <div className="row gx-xxl-5">
                          <AddressInfo />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        */}
    </>
  );
};

export default HomeIndex;
