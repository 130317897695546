const Features2 = () => {
  const listItems = [
    'The significance of asking the best-tailored soft skills questions.',
    'Helping employers update their interview questions, learn fast, and be more creative throughout the interviewing process.',
    'Great candidate experience by removing biases and fairly assessing candidates abilities during all interviews.',
  ];

  return (
    <div className="block-style-three" data-aos="fade-right">
      {/*<div className="title-style-four">*/}
      {/*  */}
      {/*</div>*/}
      {/* /.title-style-four */}
      {/*<p className="text-lg pt-35 pb-30 lg-pb-10">*/}
      {/*  Things go wrong have questions. We’ve understand. So we have people*/}
      {/*</p>*/}
      <ul className="style-none list-item fs-18">
        {listItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default Features2;
