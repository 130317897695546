import React from 'react';
import { GetServerSidePropsContext } from 'next';
import GuestPage from '../src/core/AppLayout/GuestPage';
import { canonicalDomainUrl } from '@crema/helpers';
import HomeIndex from '@src/modules/guest/home/index';
// import asyncComponent from '@crema/components/AsyncLoaderComponent';
// const HomeIndex = asyncComponent(() => import('@src/modules/guest/home/index'));

export default GuestPage((props: any) => <HomeIndex {...props} />);

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return {
    props: {
      meta: {
        title: 'InterviewsUp - The place for all the best interview questions.',
        description:
          'Save time and hire smarter. Get customized interview guides with the best questions designed for specific seniority levels.',
        keywords: 'Interviews up',
        canonical: canonicalDomainUrl('/', context.locale),
      },
      whiteHeader: true,
      footer: {
        messageCta: 1,
      },
    },
  };
}
